<template>
  <div>
    <div class="header-raper">
      <div class="portfolio-header"><Header :appMenuStatusUpdate="appMenuStatusUpdate" /></div>
      <div class="protfolio-page">
        <div class="protfolio-head">
          <h1 class="protfolio-head__protfolio-text">Portfolio</h1>
          <div class="protfolio-head__sections">
            <ul id="portfolio-sections">
              <li
                class="btn"
                v-on:click="changeKey('COMMERCE')"
                v-bind:class="{ active: portfolioSection === 'COMMERCE' }"
              >
                COMMERCE
              </li>
              <li
                class="btn"
                v-on:click="changeKey('MEDIA &amp; COMMUNITY')"
                v-bind:class="{ active: portfolioSection === 'MEDIA &amp; COMMUNITY' }"
              >
                MEDIA & ENTERTAINMENT
              </li>
              <li
                class="btn"
                v-on:click="changeKey('SOLUTION &amp; ENTERTAINMENT')"
                v-bind:class="{ active: portfolioSection === 'SOLUTION &amp; ENTERTAINMENT' }"
              >
                SOLUTION
              </li>
              <li
                class="btn product-btn"
                v-on:click="changeKey('PRODUCT')"
                v-bind:class="{ active: portfolioSection === 'PRODUCT' }"
              >
                PRODUCT
              </li>
            </ul>
          </div>
        </div>
        <div class="protfolio-images">
          <div
            class="protfolio-cards"
            v-for="(portfolio, index) in portfolioSectionList"
            v-bind:key="`${reRenderKey}-${index}`"
            @click="goToPortfolio(portfolio.title)"
          >
            <div @mouseover="onHover(index)" @mouseout="onMouseOut()">
              <PortfolioPageCard :imgUrl="baseUrl + portfolio.cardLogo" />
            </div>
          </div>
        </div>
      </div>
      <div class="subscribe-bar">
        <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
      </div>
    </div>
    <div><Footer /></div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import { Header, Footer, PortfolioPageCard, SubscribeBar } from '@/components'
export default {
  components: {
    Header,
    Footer,
    PortfolioPageCard,
    SubscribeBar
  },
  data() {
    return {
      showSusbscribeBar: true,
      portfolioSection: 'COMMERCE',
      reRenderKey: 0,
      baseUrl: BACKEND_BASE_IMG_PATH,
      onHoverIndex: ''
    }
  },
  methods: {
    ...mapActions('home', ['getCompany']),
    goToPortfolio(fieldId) {
      this.$router.push({ name: 'portfoliodetail', params: { currentIndex: fieldId } })
    },
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    changeKey(section) {
      this.portfolioSection = section
      this.reRenderKey = this.reRenderKey + 1
    },
    onHover(currentIndex) {
      this.onHoverIndex = currentIndex
    },
    onMouseOut() {
      this.onHoverIndex = ''
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  },
  computed: {
    ...mapState('home', ['posts', 'value', 'company']),
    portfolioSectionList() {
      let company = []
      if (this.portfolioSection === 'COMMERCE') {
        company = this.company.filter(item => item.field_category === 'COMMERCE')
      } else if (this.portfolioSection === 'SOLUTION & ENTERTAINMENT') {
        company = this.company.filter(item => item.field_category === 'SOLUTION &amp; ENTERTAINMENT')
      } else if (this.portfolioSection === 'PRODUCT') {
        company = this.company.filter(item => item.field_category === 'PRODUCT')
      } else {
        company = this.company.filter(item => item.field_category === 'MEDIA &amp; COMMUNITY')
      }
      return company.map((item, index) => {
        if (index === this.onHoverIndex) {
          return {
            ...item,
            cardLogo: item.field_carousal_logo
          }
        } else {
          return {
            ...item,
            cardLogo: item.field_white_logo
          }
        }
      })
    }
  },
  mounted() {
    this.getCompany()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/media-query-mixins.scss';
.header-raper {
  min-height: calc(78vh);
  .portfolio-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: $primary-white;
    z-index: 10;
  }
}
.protfolio-page {
  margin-top: 130px;
  .protfolio-head {
    margin: 50px 80px 0 80px;
    display: flex;
    justify-content: space-between;
    &__protfolio-text {
      font-size: 32px;
      font-weight: 500;
      color: $heading-black;
      line-height: 1.38;
      font-family: GothamMedium;
      text-align: left;
      animation: left-right-transition 1s;
    }
    &__sections {
      animation: right-left-transiton 1s;
      padding: 0 !important;
    }
  }
}
#portfolio-sections {
  padding-left: 0;
  margin-bottom: 0;
}
.btn {
  font-size: 14px;
  line-height: 2.86;
  color: $dim-gray;
  font-family: GothamBook;
  padding-left: 40px;
  padding-right: 0;
}
.active {
  color: $blue-active-color;
  font-family: GothamMedium;
}
.protfolio-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, 14%);
  justify-content: space-between;
  margin: 0 80px 100px 80px;
  .protfolio-cards {
    margin-bottom: 15px;
    width: 100%;
  }
}
.product-btn {
  margin-left: 10px;
}
@media (max-width: $min-width-iphone5s-SE) and (orientation: 'portrait') {
  .btn {
    font-size: 9px !important;
    letter-spacing: 0.7px !important;
  }
}
@media (max-width: 766px) and (min-width: 600px) {
  #portfolio-sections {
    justify-content: flex-start !important;
    .btn {
      margin-right: 10px;
    }
  }
}
@include iphone-portrait {
  .protfolio-page {
    .protfolio-head {
      flex-direction: column;
      margin: 30px 16px 0 16px;
      &__protfolio-text {
        font-size: 24px;
      }
      &__sections {
        #portfolio-sections {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .btn {
    padding: 0;
    font-size: 12px;
    line-height: 1.4;
  }
  .active {
    font-size: 14px;
  }
  .protfolio-images {
    margin: 20px 16px 0 16px;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 48%);
    .protfolio-cards {
      width: 100%;
      margin-bottom: 15px;
      &:nth-child(2n + 1) {
        animation: bottom-top-transition 1.2s;
      }
      &:nth-child(2n + 2) {
        animation: bottom-top-transition 1.4s;
      }
    }
  }
}
@include iphone-landscape {
  .protfolio-page {
    .protfolio-head {
      flex-direction: column;
      margin: 30px 16px 0 16px;
    }
  }
  .btn {
    padding-right: 10px;
    padding-left: 0;
    font-size: 12px;
  }
  .protfolio-images {
    margin: 20px 16px 0 16px;
    grid-template-columns: repeat(auto-fill, 25%);
    .protfolio-cards {
      width: 100%;
    }
  }
}
@include ipad-portrait {
  .protfolio-page {
    .protfolio-head {
      margin: 30px 51px 0 50px;
      flex-direction: row;
    }
  }
  .btn {
    padding-left: 30px;
  }
  .protfolio-images {
    margin: 30px 51px 0 50px !important;
    grid-template-columns: repeat(auto-fill, 19%);
    .protfolio-cards {
      width: 100%;
      &:nth-child(5n + 1) {
        animation: bottom-top-transition 0.8s;
      }
      &:nth-child(5n + 2) {
        animation: bottom-top-transition 1s;
      }
      &:nth-child(5n + 3) {
        animation: bottom-top-transition 1.2s;
      }
      &:nth-child(5n + 4) {
        animation: bottom-top-transition 1.4s;
      }
      &:nth-child(5n + 5) {
        animation: bottom-top-transition 1.6s;
      }
    }
  }
}
@include ipad-10-landscape {
  .btn {
    padding-left: 30px;
  }
  .protfolio-images {
    grid-template-columns: repeat(auto-fill, 15.9%);
    .protfolio-cards {
      width: 100%;
      &:nth-child(6n + 1) {
        animation: bottom-top-transition 0.8s;
      }
      &:nth-child(6n + 2) {
        animation: bottom-top-transition 1s;
      }
      &:nth-child(6n + 3) {
        animation: bottom-top-transition 1.2s;
      }
      &:nth-child(6n + 4) {
        animation: bottom-top-transition 1.4s;
      }
      &:nth-child(6n + 5) {
        animation: bottom-top-transition 1.6s;
      }
      &:nth-child(6n + 6) {
        animation: bottom-top-transition 1.8s;
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .protfolio-cards {
    margin-bottom: 5px !important;
  }
}
@mixin leadercard-overlay-mixin {
  .protfolio-cards {
    &:nth-child(7n + 1) {
      animation: bottom-top-transition 0.8s;
    }
    &:nth-child(7n + 2) {
      animation: bottom-top-transition 1s;
    }
    &:nth-child(7n + 3) {
      animation: bottom-top-transition 1.2s;
    }
    &:nth-child(7n + 4) {
      animation: bottom-top-transition 1.4s;
    }
    &:nth-child(7n + 5) {
      animation: bottom-top-transition 1.6s;
    }
    &:nth-child(7n + 6) {
      animation: bottom-top-transition 1.8s;
    }
    &:nth-child(7n + 7) {
      animation: bottom-top-transition 2s;
    }
  }
}
@include ipad-portrait {
  .protfolio-images {
    margin: 30px 20px 0 20px;
  }
  .protfolio-head {
    margin: 30px 20px 0 20px;
  }
}
@include ipad-default-portrait {
  .protfolio-head {
    margin-right: 51px !important;
  }
  .protfolio-images {
    margin-right: 51px !important;
  }
}
@include computers {
  @include leadercard-overlay-mixin;
  .protfolio-cards {
    margin-bottom: 5px !important;
  }
}
@include larger-screens {
  @include leadercard-overlay-mixin;
  .protfolio-cards {
    margin-right: 11px;
  }
}
@keyframes left-right-transition {
  0% {
    transform: translateX(-75%);
    opacity: 0;
  }
  100% {
    transform: translatX(0);
    opacity: 1;
  }
}
@keyframes right-left-transiton {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translatY(0);
    opacity: 1;
  }
}
@keyframes bottom-top-transition {
  0% {
    transform: translate3d(0, 50vh, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
::v-deep .portfolio-card__image {
  height: 100% !important;
  width: 100% !important;
  @media (max-width: $min-width-iphone5s-SE) and (orientation: 'portrait') {
    height: 90px !important;
    width: 140px !important;
  }
  @media (min-width: $min-width-iphone6pl-7pl-8pl) and (max-width: $max-width-iphone-11-XR-XSmax) and (orientation: 'portrait') {
    height: 100% !important;
    width: 100% !important;
  }
  @include ipad-portrait {
    height: 100% !important;
    width: 100% !important;
  }
  @include ipad-10-landscape {
    height: 100% !important;
    width: 100% !important;
  }
  @include iphone-landscape {
    height: 100% !important;
    width: 100% !important;
  }
  @include larger-screens {
    height: 100%;
    width: 100%;
  }
  @include computers {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .product-btn {
    margin-left: 4px;
  }
  .btn {
    font-size: 10px;
  }
  .active {
    font-size: 11px;
  }
}
</style>
